import yup from 'src/libs/yup';
import { dateOperatorSchema } from 'src/validators/DefaultFilter.schema';

const OpportunitiesFilterSchema = yup.object().shape({
  lead_search: yup.string().label('Lead'),
  attendant_id: yup.number().nullable().label('Responsável'),
  attendants: yup.array().of(yup.number()).nullable().label('Atendantes'),
  status: yup.array().of(yup.string()).nullable().label('Status'),
  is_waiting: yup.boolean().nullable().label('Em Espera'),
  closed_at: dateOperatorSchema.label('Data de Encerramento'),
});

export default OpportunitiesFilterSchema;
